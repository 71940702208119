<template>
  <div class="data-center">
    <h4>数据中心</h4>
    <div class="mt-3">
      <div class="form-inline">
        <div class="row">
          <div class="col-auto">
            <input type="date" class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="home my-5">
      <div class="row mt-3">
        <line-chart :chart-data="chartData"  :width="3" :height="1" class="w-100"/>
      </div>
      <table class="table mt-3">
        <thead>
        <tr>
          <th>渠道</th>
          <th>兑换（张）</th>
          <th>刮票（张）</th>
          <th>中奖（张）</th>
          <th>销售额（元）</th>
          <th>奖金（元）</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in list" :key="row.index">
          <td v-for="item in row" :key="item.index">{{ item }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import LineChart from "@/components/LineChart";

export default {
  name: 'Home',
  components: {
    LineChart
  },
  data() {
    return {
      list: [
        ['今日',10000,9000,2000,60000,30000],
        ['最近日天',10000,9000,2000,60000,30000],
        ['最近30天',10000,9000,2000,60000,30000],
      ],
      chartData: {},
      colors: []

    }
  },
  mounted() {
    this.fillData()
  },
  methods: {
    initColors() {
      this.colors = [
        "#3e95cd", '#f87979', '#f5063e', '#9a05f1', '#3CBA9FFF', '#F6D88BFF', '#F5063EFF', '#055AF1FF', '#9A05F1FF'
      ];
    },
    randomColor() {
      return this.colors.pop();
    },
    getData() {
      return {
        titles:['有效率','成交率', '复购率'],
        labels:['2022-01-01','2022-01-02','2022-01-03','2022-01-04','2022-01-05','2022-01-06','2022-01-07'],
        dataSets:[
          [
            {label:'美团',data:[10, 34, 26, 35, 56, 36 ,10]},
            {label:'点评',data:[4, 24, 36, 45, 56, 66 ,70]},
            {label:'抖音',data:[12, 32, 13, 21, 25, 24 ,20]},
          ],
          [
            {label:'美团',data:[10, 34, 26, 35, 56, 36 ,10]},
            {label:'点评',data:[12, 32, 13, 21, 25, 24 ,20]},
            {label:'抖音',data:[4, 24, 36, 45, 56, 66 ,70]},
          ],
          [
            {label:'美团',data:[10, 34, 26, 35, 56, 36 ,10]},
            {label:'点评',data:[10, 34, 26, 35, 56, 36 ,10]},
            {label:'抖音',data:[10, 34, 26, 35, 56, 36 ,10]},
          ]
        ]}
    },
    fillData (t = 0) {
      let activeType = t * 1;
      let eData = this.getData();
      let eDataSets = [];
      for (let i in eData.dataSets[activeType]) {
        let _data = eData.dataSets[activeType][i];
        eDataSets[i] = {
          label: _data.label,
          borderColor: this.randomColor(),
          data: _data.data,
          fill: false
        }
      }
      this.chartData = {
        labels: eData.labels,
        datasets: eDataSets
      }
      console.log(this.chartData)
      this.initColors();
      this.$forceUpdate()
    },
  }

}
</script>
